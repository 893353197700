export const sideBarTabs = [
  "QR Codes",
  "Analytics",
  // "QR Codes",
  // "Pages",
  // "Analytics",
  // "Campaigns",
  // "Custom links",
  // "Settings",
];
