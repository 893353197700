import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import BitlyLinks from "./pages/Home/Bitly";
import Login from "./pages/Login/Login";
import PrivateRoute from "../src/pages/PrivateRoute/PrivateRoute"; // Ensure this path is correct
import SignUp from "./pages/SignUp/SignUp";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<BitlyLinks />} /> {/* Nested route */}
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
