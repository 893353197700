import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "./GenerateQr.module.css";
import { images } from "../assets";
import {
  previewQrCode,
  getAllFrames,
  createQrCode,
  updateQrCode,
} from "../services";

const GenerateQr = ({ qr, setQr, setCreateNew }) => {
  const [color, setColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("#ffff");
  const [logo, setLogo] = useState(null);
  const [logoBase64, setLogoBase64] = useState("");
  const [title, setTitle] = useState("");
  const [targetLink, setTargetLink] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  const [previewResponse, setPreviewResponse] = useState(null);
  const [frames, setFrames] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [qrId, setQrId] = useState(null);

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    setLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const fetchPreview = async () => {
      const payload = {
        targetUrl: targetLink,
        logo: logoBase64.replace(/^data:image\/[a-z]+;base64,/, ""),
        qrColor: color,
        backgroundColor: backgroundColor,
        frameTemplate: selectedFrame.template,
        title: title,
      };
      try {
        const response = await previewQrCode(payload);
        setPreviewResponse(response?.qrImageUrl);
      } catch (error) {
        console.error("Error fetching preview", error);
      }
    };

    showPreview && fetchPreview();
  }, [
    title,
    targetLink,
    color,
    backgroundColor,
    logoBase64,
    showPreview,
    selectedFrame,
  ]);

  useEffect(() => {
    if (qr) {
      setTitle(qr.title);
      setTargetLink(qr.targetUrl);
      setColor(qr.qrColor);
      setBackgroundColor(qr.backgroundColor);
      setPreviewResponse(qr.qrImageUrl);
      setSelectedFrame(qr.frameTemplate);
      setLogoBase64(`data:image/png;base64,${qr.logo}`);
      setQrId(qr.codeId);
      setShowPreview(true);
    }
  }, [qr]);

  useEffect(() => {
    const fetchAllFrames = async () => {
      try {
        const response = await getAllFrames();
        setFrames(response);
        setSelectedFrame(response[0]);
      } catch (error) {
        console.error("Error fetching preview", error);
      }
    };

    fetchAllFrames();
  }, []);

  const handleReset = () => {
    setColor("#000000");
    setBackgroundColor("#fff");
    setLogo(null);
    setLogoBase64("");
    setQrId(null);
    setPreviewResponse(null);
    setPreviewError(null);
    setQr(null);
  };

  const handlePreview = () => {
    if (targetLink) {
      setShowPreview(true);
      setPreviewError(false);
    } else {
      setPreviewError(true);
    }
  };

  const handleCreateQr = async () => {
    if (targetLink) {
      console.log(title);
      setPreviewError(false);
      const payload = {
        targetUrl: targetLink,
        logo: logoBase64.replace(/^data:image\/[a-z]+;base64,/, ""),
        qrColor: color,
        backgroundColor: backgroundColor,
        frameTemplate: selectedFrame.template,
        title: title,
      };
      try {
        const response = (await qr)
          ? updateQrCode(qrId, payload)
          : createQrCode(payload);
        if (response) {
          setCreateNew(false);
        }
      } catch (error) {
        console.error("Error fetching preview", error);
      }
    } else {
      setPreviewError(true);
    }
  };
  const handleCancel = () => {
    handleReset();
    setCreateNew(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftPane}>
        <Typography variant="h4" gutterBottom>
          Customize QR Code
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <TextField
            label="Target Link *"
            variant="outlined"
            className={styles.formField}
            sx={{ width: "auto", flex: "1" }}
            error={previewError}
            onChange={(e) => setTargetLink(e.target.value)}
            value={targetLink}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePreview}
            className={styles.resetButton}
          >
            Preview
          </Button>
        </Box>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          className={styles.formField}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />

        <FormControl fullWidth className={styles.formField}>
          <InputLabel>Color</InputLabel>
          <Select
            label="Color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            renderValue={(selected) => (
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: selected,
                    mr: 1,
                  }}
                />
                {selected}
              </Box>
            )}
          >
            <MenuItem value="#000000">Black</MenuItem>
            <MenuItem value="#FF0000">Red</MenuItem>
            <MenuItem value="#00FF00">Green</MenuItem>
            <MenuItem value="#0000FF">Blue</MenuItem>
            {/* Add more colors as needed */}
          </Select>
        </FormControl>
        <FormControl fullWidth className={styles.formField}>
          <InputLabel>Background Color</InputLabel>
          <Select
            label="Background Color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
            renderValue={(selected) => (
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: selected,
                    mr: 1,
                  }}
                />
                {selected}
              </Box>
            )}
          >
            <MenuItem value="#FFFFFF">White</MenuItem>
            <MenuItem value="#000000">Black</MenuItem>
            <MenuItem value="#FF0000">Red</MenuItem>
            <MenuItem value="#00FF00">Green</MenuItem>
            <MenuItem value="#0000FF">Blue</MenuItem>
            {/* Add more colors as needed */}
          </Select>
        </FormControl>
        <Box>
          <Typography variant="h6" gutterBottom>
            Select Frame
          </Typography>
          <Box className={styles.framesContainer}>
            {frames?.map((frame, index) => (
              <img
                key={index}
                src={`data:image/jpeg;base64,${frame.frameData}`}
                alt={`Frame ${index}`}
                className={`${styles.framePreview} ${
                  selectedFrame === frame ? styles.selectedFrame : ""
                }`}
                onClick={() => setSelectedFrame(frame)}
              />
            ))}
          </Box>
        </Box>
        <Box className={styles.logoUploadContainer}>
          <Button
            variant="contained"
            component="label"
            className={styles.logoUpload}
          >
            Upload Logo
            <input type="file" hidden onChange={handleLogoUpload} />
          </Button>
          <Box className={styles.logoPreviewContainer}>
            <img
              src={logoBase64 || images?.placeHolderImage}
              alt="Uploaded Logo"
              className={styles.logoPreview}
            />
          </Box>
          {logo && (
            <Typography variant="body2" className={styles.logoFileName}>
              {logo.name}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: 10,
            right: 10,
            gap: "1rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            className={styles.resetButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateQr}
            className={styles.resetButton}
          >
            Save
          </Button>
        </Box>
      </div>
      <div className={styles.rightPane}>
        <Typography variant="h4" gutterBottom>
          Preview
        </Typography>
        <Box className={styles.previewContainer}>
          {previewResponse ? (
            <img
              className={styles.previewImg}
              src={previewResponse}
              alt="QR Preview"
            />
          ) : (
            <Typography variant="body2" className={styles.previewText}>
              QR Code Preview
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReset}
          className={styles.resetButton}
        >
          Reset to Default
        </Button>
      </div>
    </div>
  );
};

export default GenerateQr;
