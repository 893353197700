import { Box, Typography, ButtonGroup, Button } from "@mui/material";
import { scaleLinear } from "d3-scale";
import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import worldMapData from "./world-110m.json"; // Path to your local JSON file
import styles from "./AnalyticsMap.module.css";

const colorScale = scaleLinear()
  .domain([0, 30]) // Adjust the domain based on your data range
  .range(["#e0f7fa", "#006064"]);

const GradientRuler = () => {
  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Typography variant="caption" mr={1}>
        0
      </Typography>
      <Box
        width={200}
        height={20}
        style={{
          background: `linear-gradient(to right, ${colorScale(0)}, ${colorScale(
            700
          )})`,
        }}
      />
      <Typography variant="caption" ml={1}>
        700
      </Typography>
    </Box>
  );
};

const AnalyticsMap = ({ data }) => {

  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(1);

  const countryScans = data.reduce((acc, item) => {
    acc[item["_id"]] = item?.totalScans;
    return acc;
  }, {});


  console.log("map",countryScans);


  const handleZoomIn = () => {
    if (zoomLevel < 4) {
      setZoomLevel(zoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.5) {
      setZoomLevel(zoomLevel - 0.1);
    }
  };

  return (
    <Box position="relative" width="90%" height="80%" bgcolor="white">
      <Typography>Clicks + scans by location</Typography>
      <ComposableMap>
        <ZoomableGroup zoom={zoomLevel}>
          <Geographies geography={worldMapData}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const { name } = geo.properties;
                const scans = countryScans[name] || 0;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(event) => {
                      const { clientX, clientY } = event;
                      setTooltipContent(`${name}: ${scans} scans`);
                      setTooltipPosition({ x: clientX, y: clientY });
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      default: {
                        fill: colorScale(scans),
                        outline: "none",
                      },
                      hover: {
                        fill: "#0288d1",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#01579b",
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {tooltipContent && (
        <Box
          position="absolute"
          top={tooltipPosition.y -350}
          left={tooltipPosition.x - 900}
          transform="translate(-50%, -100%)"
          bgcolor="rgba(0, 0, 0, 0.7)"
          color="white"
          p={1}
          borderRadius={3}
          pointerEvents="none"
          zIndex={10}
        >
          <Typography variant="body2">{tooltipContent}</Typography>
        </Box>
      )}
      <Box className={styles.gradientRuler}>
        <GradientRuler />
      </Box>
      <Box position="absolute" bottom={30} right={0}>
        <ButtonGroup
          variant="outlined"
          aria-label="zoom buttons"
          orientation="vertical"
        >
          <Button onClick={handleZoomIn} className={styles.zoomBtn}>
            +
          </Button>
          <Button className={styles.zoomBtn} onClick={handleZoomOut}>
            -
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default AnalyticsMap;
