import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext"; // Ensure this path is correct

const PrivateRoute = () => {
  const { auth } = useContext(AuthContext);

  console.log(auth);

  return auth ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
