import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Login.module.css"; // Import the module.css file
import { login as loginService } from "../../services/index"; // Ensure this path is correct
import { AuthContext } from "../../context/AuthContext"; // Ensure this path is correct

function Login() {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleLogin = async () => {
    setErrorMessage("");
    setHasError(false);
    setLoading(true);
    try {
      const response = await loginService({
        username: email,
        password: password,
      });
      login(response?.AuthenticationResult?.AccessToken); // Store the auth token in context
      setOpenSnackbar(true);
      navigate("/");
    } catch (error) {
      console.error("Error fetching preview", error);
      if (error.response && error.response.data) {
        if (error.response.status === 400) {
          setErrorMessage("Incorrect password or username");
        } else {
          setErrorMessage(error.response.data.message || "Login failed");
        }
      } else {
        setErrorMessage("An unexpected error occurred");
      }
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container component="main" className={styles.root}>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={styles.paper}>
          <Typography
            component="h1"
            variant="h5"
            align="left"
            className={styles.loginHeading}
          >
            Log in and start sharing
          </Typography>
          <Typography variant="body2" color="textSecondary" align="left">
            Don't have an account?{" "}
            <Link to="/signup" className={styles.link}>
              Sign up
            </Link>
          </Typography>
          <form className={styles.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={hasError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={hasError}
            />
            <Typography variant="body2" color="textSecondary" align="right">
              <Link to="/forgot-password" className={styles.link}>
                Forgot password?
              </Link>
            </Typography>
            {errorMessage && (
              <Typography
                variant="body2"
                color="error"
                align="center"
                sx={{ marginBottom: "1rem" }}
              >
                {errorMessage}
              </Typography>
            )}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={styles.submit}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Sign In"}
            </Button>
            <Typography variant="body2" color="textSecondary" align="center">
              By logging in with an account, you agree to QR's{" "}
              <Link to="/terms" className={styles.link}>
                Terms of Service
              </Link>
              ,{" "}
              <Link to="/privacy" className={styles.link}>
                Privacy Policy
              </Link>
              , and{" "}
              <Link to="/acceptable-use" className={styles.link}>
                Acceptable Use Policy
              </Link>
              .
            </Typography>
          </form>
        </div>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Login successful!
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default Login;
