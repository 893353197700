import * as React from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerComponent({ startDate, setStartDate, endDate, setEndDate }) {
//   const [startDate, setStartDate] = useState(dayjs('2022-04-17'));
//   const [endDate, setEndDate] = useState(dayjs('2022-04-24'));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newStartDate) => {
            setStartDate(newStartDate);
            // Reset end date if it's before the new start date
            if (newStartDate && endDate && newStartDate.isAfter(endDate)) {
              setEndDate(null);
            }
          }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          minDate={startDate.add(1, 'day')}  // Set the minimum date to one day after the start date
          onChange={setEndDate}
          disabled={!startDate}  // Disable this picker until a start date is chosen
        />
      </div>
    </LocalizationProvider>
  );
}
