import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Typography } from "@mui/material";

// Custom gradient colors for the charts
const dummyData = [
  { name: "Desktop", value: 146, color: "#00c49f" },
  { name: "E-Reader", value: 101, color: "#00c0ef" },
  { name: "ios", value: 70, color: "#8884d8" },
  { name: "android", value: 50, color: "#ffbb28" },
  { name: "Unknown", value: 14, color: "#ff8042" },
];

const dummyData2 = [
  { name: "Page A", uv: 2400, pv: 2400 },
  { name: "Page B", uv: 1398, pv: 2210 },
  { name: "Page C", uv: 9800, pv: 2290 },
  { name: "Page D", uv: 3908, pv: 2000 },
  { name: "Page E", uv: 4800, pv: 2181 },
  { name: "Page F", uv: 3800, pv: 2500 },
  { name: "Page G", uv: 4300, pv: 2100 },
];

const CustomizedTooltip = ({ payload, label }) => {
  if (payload.length === 0) return null;
  return (
    <div
      className="custom-tooltip"
      style={{
        backgroundColor: "#fff",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      <p>{label}</p>
      {payload.map((entry, index) => (
        <p key={`item-${index}`} style={{ color: entry.color }}>
          {`${entry.name}: ${entry.value}`}
        </p>
      ))}
    </div>
  );
};


const convertPayloadToChartData = (payload, dummyData) => {
  const chartData = [];

  dummyData.forEach(item => {
    const key = item.name.toLowerCase();
    if (payload[key] !== undefined) {
      chartData.push({ name: item.name, value: payload[key], color: item.color });
    }
  });

  return chartData;
};

const AnalyticsChart = ({ chartType ,data,title}) => {
  let chartData = dummyData; // Initialize chartData with dummyData for non-pie charts
  let isDataEmpty=false;
  if (chartType === 'pie') {
    console.log(data,"uuuuuuuuuuuuuuuuuuuuuu")
    chartData = convertPayloadToChartData(data, dummyData); // Update chartData for pie chart
    console.log(chartData,"the char data");
    isDataEmpty = chartData.every(item => item.value === 0);
  }

  
  const renderChart = () => {
    switch (chartType) {
      case "line":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <defs>
                <linearGradient id="pvGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="uvGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomizedTooltip />} />
              <Legend />
              {/* <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                fill="url(#pvGradient)"
              /> */}
              <Line
                type="monotone"
                dataKey="uv"
                name="scans"
                stroke="#82ca9d"
                fill="url(#uvGradient)"
              />
            </LineChart>
          </ResponsiveContainer>
        );
      case "bar":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              {/* <CartesianGrid/> */}
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip content={<CustomizedTooltip />} />
              <Legend />
              <Bar dataKey="totalClicks" fill="url(#pvGradient)" />
            </BarChart>
          </ResponsiveContainer>
        );
      case "pie":
        return (
          <div style={{ width: '100%', height: 300 }}>
          {isDataEmpty ? (
            <div style={{ textAlign: 'center', lineHeight: '300px', fontSize: '18px', color: '#8884d8' }}>
              No Data Available
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#8884d8"
                  paddingAngle={2}
                  cornerRadius={5}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Legend
                  layout="vertical"
                  align="right"
                  verticalAlign="middle"
                  wrapperStyle={{ paddingLeft: '20px' }}
                  payload={chartData.map((item) => ({
                    id: item.name,
                    type: 'square',
                    value: `${item.name}: ${item.value}`,
                    color: item.color,
                  }))}
                />
                <Tooltip content={<CustomizedTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        );
      default:
        return <div>Invalid chart type</div>;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
     {title}
      </Typography>
      {renderChart()}
    </Box>
  );
};

export default AnalyticsChart;
