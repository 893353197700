import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Link,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  Info as InfoIcon,
  BarChart as BarChartIcon,
  Edit as EditIcon,
  GetApp as GetAppIcon,
  Palette as PaletteIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

export const QrCodeCard = ({ qrCode, setQr, setCreateNew }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card elevation={2} style={{ marginBottom: 20 }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Box mr={2} onClick={handleOpen} style={{ cursor: "pointer" }}>
            <img
              src={qrCode.qrImageUrl}
              alt={qrCode.title}
              style={{ width: 100, height: 100 }}
            />
          </Box>
          <Box flex={1}>
            <Typography variant="h6" component="div">
              {qrCode.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {qrCode.description}
            </Typography>
            <Link
              href={qrCode.link}
              target="_blank"
              rel="noopener"
              variant="body2"
            >
              {qrCode.link}
            </Link>
            <Box display="flex" alignItems="center" mt={1}>
              <CalendarMonthIcon />
              <Typography variant="caption" color="textSecondary" ml={2}>
                {formatDate(qrCode.createdAt)}
              </Typography>
              <Link
                href={`https://${qrCode.bitlyLink}`}
                target="_blank"
                rel="noopener"
                variant="caption"
                ml={2}
              >
                {qrCode.targetUrl}
              </Link>
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setQr(qrCode);
                setCreateNew(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img
            src={qrCode.qrImageUrl}
            alt={qrCode.title}
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default QrCodeCard;
