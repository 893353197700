import axiosInstance from "../axios";
import { format } from 'date-fns';

export const login = async (data) => {
  try {
    const response = await axiosInstance.post("api/auth/signin", data);
    return response.data;
  } catch (error) {
    console.error("Error posting data", error.response);
    throw error;
  }
};

export const previewQrCode = async (data) => {
  try {
    const response = await axiosInstance.post("api/qrcodes/preview", data);
    return response.data;
  } catch (error) {
    console.error("Error posting data", error);
  }
};

export const getAllFrames = async () => {
  try {
    const response = await axiosInstance.get("api/frames");
    return response.data;
  } catch (error) {
    console.error("Error posting data", error);
  }
};

export const getAllQrCodes = async () => {
  try {
    const response = await axiosInstance.get("api/qrcodes");
    return response.data;
  } catch (error) {
    console.error("Error posting data", error);
  }
};

export const createQrCode = async (data) => {
  try {
    const response = await axiosInstance.post("api/qrcodes", data);
    return response.data;
  } catch (error) {
    console.error("Error posting data", error);
  }
};

export const updateQrCode = async (qrid, payload) => {
  try {
    const response = await axiosInstance.put(`api/qrcodes/${qrid}`, payload);
    return response.data;
  } catch (error) {
    console.error("Error posting data", error);
  }
};

export const fetchTopScans = async (startDate, endDate, page, limit, type, codeId ) => {
  try {
    console.log("fecthing my thing")
    console.log(startDate,endDate)
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');
    console.log("test")
    console.log( {
      startDate: formattedStartDate, // Ensure startDate and endDate are passed as Day.js objects
      endDate:formattedEndDate,
      page:1,
      limit:10,
      type,
      codeId
    })
    const response = await axiosInstance.get(`api/analytics/top-scans`, {
      params: {
        startDate: formattedStartDate, // Ensure startDate and endDate are passed as Day.js objects
        endDate:formattedEndDate,
        page:1,
        limit:10,
        type,
        codeId
      }
    });
    console.log(response,"*****")
    return response.data;
  } catch (error) {
    console.error("Error fetching top scans data", error);
    return null; // Optionally return null or an empty object/array to handle errors gracefully in your component
  }
};

export const fetchPerformingDate = async ( startDate, endDate, selectedCodeId ) => {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    if (selectedCodeId) {
      params.codeId = selectedCodeId;
    }

    const response = await axiosInstance.get(`/api/analytics/top-performing-date`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching top performing date statistics", error);
    throw error; // You can choose to throw the error or handle it here
  }
};

export const getUserAgentStats = async (startDate, endDate,selectedCodeId) => {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    if (selectedCodeId) {
      params.codeId = selectedCodeId;
    }

    const response = await axiosInstance.get(`/api/analytics/user-agent-stats`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching user-agent statistics", error);
    throw error; // You can choose to throw the error or handle it here
  }
};

export const getScansByLocation = async (startDate, endDate,selectedCodeId) => {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      type: 'country'
    };

    if (selectedCodeId) {
      params.codeId = selectedCodeId;
    }

    const response = await axiosInstance.get(`/api/analytics/top-scans`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching scans by location", error);
    throw error; // You can choose to throw the error or handle it here
  }
};

export const getClickScanOverTime = async (startDate, endDate,selectedCodeId) => {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    if (selectedCodeId) {
      params.codeId = selectedCodeId;
    }

    const response = await axiosInstance.get(`/api/analytics/clicks-scans-over-time`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching user-agent statistics", error);
    throw error; // You can choose to throw the error or handle it here
  }
};

export const getPerfromingLocations = async (startDate, endDate) => {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const response = await axiosInstance.get(`/api/analytics/top-scans`, {
      params: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        page:1,
        limit:10,
        type:'country'

      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user-agent statistics", error);
    throw error; // You can choose to throw the error or handle it here
  }
};

export const exportCSV = async (startDate, endDate,selectedCodeId) => {
  try {
    const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    if (selectedCodeId) {
      params.codeId = selectedCodeId;
    }

    const response = await axiosInstance.get(`/api/export/scan-logs`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching user-agent statistics", error);
    throw error; // You can choose to throw the error or handle it here
  }
};

