import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { format } from 'date-fns';
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import styles from "./AnalyticsTable.module.css";
import { fetchTopScans } from "../../services";

// const data = {
//   cities: [
//     { name: "City 1", scans: 100, percentage: "20%" },
//     { name: "City 2", scans: 200, percentage: "40%" },
//     { name: "City 3", scans: 150, percentage: "30%" },
//   ],
//   countries: [
//     { name: "Country 1", scans: 300, percentage: "50%" },
//     { name: "Country 2", scans: 200, percentage: "33.33%" },
//     { name: "Country 3", scans: 100, percentage: "16.67%" },
//   ],
// };

function AnalyticsTable({ selectedCodeId, startDate, endDate }) {

  const [tab, setTab] = useState(0);
  const [data, setData] = useState({ cities: [], countries: [] });
  const [sortConfig, setSortConfig] = useState({
    key: "scans",
    direction: "asc",
  });

  const convertData = (response) => {
    const totalScans = response.reduce((sum, item) => sum + item.totalScans, 0);
    return response.map((item) => ({
      name: item._id,
      scans: item.totalScans,
      percentage: ((item.totalScans / totalScans) * 100).toFixed(2) + "%",
    }));
  };

  const fetchData = async (type,startDate,endDate) => {
    try {
      console.log(startDate,"step uno")
      const response = await fetchTopScans(startDate,endDate,1,10,type,selectedCodeId);
      const convertedData = convertData(response);
      if (type === "city") {
        setData((prevState) => ({
          ...prevState,
          cities: convertedData,
        }));
      } else {
        setData((prevState) => ({
          ...prevState,
          countries: convertedData,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
  useEffect(() => {
    // const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
    // const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

    // console.log(formattedEndDate,formattedStartDate);
    console.log("test")
    if (startDate && endDate) {
      console.log(startDate,endDate)
      const type = tab === 0 ? "city" : "country";
      fetchData(type,startDate,endDate);
    }
  }, [tab,startDate,endDate,selectedCodeId]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = (data) => {
    const sorted = [...data];
    sorted.sort((a, b) => {
      if (sortConfig.key === "percentage") {
        const aValue = parseFloat(a[sortConfig.key]);
        const bValue = parseFloat(b[sortConfig.key]);
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }
      return sortConfig.direction === "asc"
        ? a[sortConfig.key] - b[sortConfig.key]
        : b[sortConfig.key] - a[sortConfig.key];
    });
    return sorted;
  };

  const renderTable = (data) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "70%" }}>
              {tab === 0 ? "City" : "Country"}
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              <TableSortLabel
                active={sortConfig.key === "scans"}
                direction={sortConfig.direction}
                onClick={() => handleSort("scans")}
                IconComponent={() =>
                  sortConfig.direction === "asc" ? (
                    <ArrowUpward
                      style={{ color: "black", fontSize: "small" }}
                    />
                  ) : (
                    <ArrowDownward
                      style={{ color: "black", fontSize: "small" }}
                    />
                  )
                }
              >
                Scans
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: "15%" }}>
              <TableSortLabel
                active={sortConfig.key === "percentage"}
                direction={sortConfig.direction}
                onClick={() => handleSort("percentage")}
                IconComponent={() =>
                  sortConfig.direction === "asc" ? (
                    <ArrowUpward
                      style={{ color: "black", fontSize: "small" }}
                    />
                  ) : (
                    <ArrowDownward
                      style={{ color: "black", fontSize: "small" }}
                    />
                  )
                }
              >
                Percentage
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No data
              </TableCell>
            </TableRow>
          ) : (
            sortedData(data).map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: "70%" }}>{row.name}</TableCell>
                <TableCell sx={{ width: "15%" }}>{row.scans}</TableCell>
                <TableCell sx={{ width: "15%" }}>{row.percentage}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box className={styles.container}>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="data tabs"
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Cities" />
        <Tab label="Countries" />
      </Tabs>
      <Box className={styles.tableContainer}>
        {tab === 0 ? renderTable(data.cities) : renderTable(data.countries)}
      </Box>
    </Box>
  );
}

export default AnalyticsTable;
