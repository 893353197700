import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AnalyticsChart from "../ChartComponent/AnalyticsChart";
import AnalyticsMap from "../ChartComponent/AnalyticsMap";
import AnalyticsTable from "../ChartComponent/AnalyticsTable";
import DateRangeSelector from "../DateRangePicker/DateRangePicker";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import DatePickerRange from "../DateRangePicker/DateRangePicker";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { exportCSV, fetchPerformingDate, fetchUserAgentStatus, getAllQrCodes, getClickScanOverTime, getPerfromingLocations, getScansByLocation, getUserAgentStats } from "../../services";
import dayjs from 'dayjs';
import InsightsIcon from '@mui/icons-material/Insights';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const mapData = [
  { country: "United States", scans: 500 },
  { country: "India", scans: 300 },
  { country: "China", scans: 700 },
  { country: "Brazil", scans: 200 },
  { country: "Russia", scans: 250 },
  { country: "Australia", scans: 100 },
  { country: "Canada", scans: 150 },
  { country: "Germany", scans: 400 },
  { country: "France", scans: 350 },
  { country: "United Kingdom", scans: 450 },
  { country: "South Africa", scans: 80 },
  { country: "Japan", scans: 600 },
  { country: "South Korea", scans: 500 },
];

function Analytics() {
  const [titles, setTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedCodeId, setSelectedCodeId] = useState('');
  const [endDate, setEndDate] = useState(dayjs()); // Today's date
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [stats, setStats] = useState({});
  const [piestats,setpiestats]=useState({});
  const [barGraph,setbarGraph]=useState({});
  const[mapData,setmapData]=useState([]);
  const [performDate, setPerformDate] = useState('');
  const [performCount, setPerformCount] = useState(0);
  const [topLocation, setTopLocation] = useState('');
  const [topLocationScans, setTopLocationScans] = useState(0);
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    // Example URL, replace with your actual API endpoint
    const fetchData = async () => {
      try {
        const response = await getAllQrCodes();
   
        setTitles(response.map(item => ({ title: item.title, codeId: item.codeId })));
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchData();
  }, [startDate,endDate,selectedCodeId]);

  useEffect(() => {
    // Example URL, replace with your actual API endpoint

    const fetchData = async () => {
      try {
        
        const response = await fetchPerformingDate(startDate,endDate,selectedCodeId);
    
        setPerformCount(response?.count);
        setPerformDate(dayjs(response?._id).format('MMM DD, YYYY'));
   
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchData();
  }, [startDate,endDate,selectedCodeId]);

  // const formatDataForChart = (data) => {
  //   return Object.keys(data).map(key => ({
  //     name: key,
  //     uv: data[key],
  //     pv: 1000 // Assigning a constant value for pv
  //   }));
  // };
  const formatDataForChart = (data) => {
    return data.map(item => ({
      name: item.date,
      uv: item.totalClicks,
    }));
  };

  useEffect(()=>{
    const featchData=async()=>{
      try{
        const response = await getUserAgentStats(startDate,endDate,selectedCodeId);
        setpiestats(response);
        const formattedData = formatDataForChart(response);
        setStats(formattedData);
      }
      catch(error){
        console.error('Failed to fetch data', error);
      }
    }
    featchData()
  },[startDate,endDate,selectedCodeId])

  useEffect(() => {
    // Example URL, replace with your actual API endpoint
    const fetchData = async () => {
      try {
        const response = await getClickScanOverTime(startDate,endDate,selectedCodeId);
        const formattedData = formatDataForChart(response);
        setbarGraph(formattedData)
       
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchData();
  }, [startDate,endDate,selectedCodeId]);

  useEffect(() => {
    // Example URL, replace with your actual API endpoint
    const fetchData = async () => {
      try {
        const response = await getScansByLocation(startDate,endDate,selectedCodeId);
        setmapData(response)
       
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchData();
  }, [startDate,endDate,selectedCodeId]);

  useEffect(() => {
    // Example URL, replace with your actual API endpoint
    const fetchData = async () => {
      try {
        const response = await getPerfromingLocations(startDate,endDate);
        if (response && response.length > 0) {
          setTopLocation(response[0]._id);
          setTopLocationScans(response[0].totalScans);
        }
       
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchData();
  }, [startDate,endDate,selectedCodeId]);
  

  const handleTitleChange = (event) => {
    const { value } = event.target;
    const selected = titles.find(t => t.title === value);

    // Set state for title and codeId
    setSelectedTitle(value);
    if (selected) {
        setSelectedCodeId(selected.codeId);
    }

    // Log the new values directly
    console.log(value);  // Log the newly selected title
    if (selected) {
        console.log(selected.codeId);  // Log the code ID of the newly selected title
    }
    console.log(startDate.format('YYYY-MM-DD') + " --> " + endDate.format('YYYY-MM-DD'));  // Assuming startDate and endDate are available in this scope
};

const exportCSV = async (startDate, endDate) => {
  let url = `${process.env.REACT_APP_BASE_URL}api/export/scan-logs?startDate=${startDate}&endDate=${endDate}`;
   
  return fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    });
};

const downloadCSV = async() => {
  try {
    const response = await exportCSV(startDate, endDate);
    console.log('Response:', response); // Debugging: Log the response object

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const csvData = await response.text();
    console.log('CSV Data:', csvData); // Debugging: Log the CSV data

    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`;
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error fetching the CSV data:', error);
  }
}


  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box id="cc-inputBoxContainer" sx={{
        display: 'flex',
        flexDirection: 'row',
        gap:"20px"
      }}>
      <DatePickerRange startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
      <Box sx={{paddingBottom:"15px",display:"flex",alignItems:"center",gap:"10px"}}>
      <FormControl sx={{width:"200px"}}>
      <InputLabel id="title-select-label">QR code</InputLabel>
      <Select
        labelId="title-select-label"
        id="title-select"
        value={selectedTitle}
        label="Title"
        onChange={handleTitleChange}
      >
        {titles.map((item, index) => (
          <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <Button  variant="contained" color="success" startIcon={<DownloadForOfflineIcon />} onClick={downloadCSV}>Download CSV</Button>
    </Box>
   
    </Box>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: "background.paper", borderRadius: "10px" }}
          >
            <Box sx={{minHeight:"21.5rem"}}>
            <Typography variant="h6" sx={{ mb: 2 }}>
            Top performing date
            </Typography>
            <Box sx={{
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',      // Center vertically
        height: '19rem',
              // Take up remaining height
      }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}><InsightsIcon />{performDate}</Typography>
                <Typography variant="h6">{performCount} clicks+scan</Typography>
                <Typography variant="h6" sx={{ opacity: 0.6 }}>{startDate.format('MMM DD')} - {endDate.format('MMM DD, YYYY')}</Typography>
            </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: "background.paper", borderRadius: "10px" }}
          >
            <Box sx={{minHeight:"21.5rem"}}>
            <Typography variant="h6" sx={{ mb: 2 }}>
            Top performing locations
            </Typography>
            <Box sx={{
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',      // Center vertically
        height: '19rem',
              // Take up remaining height
      }}>
                 <Typography variant="h5" sx={{ fontWeight: 'bold' }}><InsightsIcon />{topLocation}</Typography>
                <Typography variant="h6">{topLocationScans} clicks+scan</Typography>
                <Typography variant="h6" sx={{ opacity: 0.6 }}>{startDate.format('MMM DD')} - {endDate.format('MMM DD, YYYY')}</Typography>
            </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: "background.paper", borderRadius: "10px" }}
          >
            <AnalyticsChart chartType="line" data={barGraph} key={barGraph} title={'Clicks + scans over time'}/>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: "background.paper", borderRadius: "10px" }}
          >
            <AnalyticsChart chartType="bar" data={barGraph} title={'Clicks + scans by referrer'}/>
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: "background.paper", borderRadius: "10px" }}
          >
            <AnalyticsChart chartType="pie" data={piestats} title={'Clicks + scans by device'}/>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper
            elevation={3}
            sx={{ p: 2, bgcolor: "background.paper", borderRadius: "10px" ,minHeight:"23rem"}}
          >
            <AnalyticsTable endDate={endDate} startDate={startDate} selectedCodeId={selectedCodeId}/>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper elevation={3} sx={{ p: 2, bgcolor: "background.paper" }}>
            <AnalyticsMap data={mapData} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Analytics;
