import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Analytics from "../../components/Analytics/Analytics";
import GeneratedQrList from "../../components/GeneratedQrList";
import { sideBarTabs } from "../../util/constants";
import styles from "./BitlyLinks.module.css"; // Keep the existing CSS for other styles

const BitlyLinks = () => {
  const [selectedTab, setSelectedTab] = useState(sideBarTabs[0]);

  const renderContent = () => {
    switch (selectedTab) {
      case "QR Codes":
        return <GeneratedQrList />;
      // case "Links":
      //   return <LinksContent />;
      case "Analytics":
        return <Analytics />;
      // case "Settings":
      //   return <SettingsContent />;
      default:
        return <></>;
    }
  };

  return (
    <div className={styles["bitly-links-container"]}>
      <Drawer
        variant="permanent"
        className={styles.drawer}
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 240,
            boxSizing: "border-box",
            backgroundColor: "#2c3e50", // Sidebar background color
            color: "#ecf0f1", // Text color
          },
        }}
      >
        <div className={styles.drawerContent}>
          <List>
            {sideBarTabs?.map((text) => (
              <ListItem
                button
                key={text}
                onClick={() => setSelectedTab(text)}
                className={
                  selectedTab === text ? styles.selectedTab : styles.listItem
                }
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={styles["main-content"]}>
        <header className={styles.header}>
          <Typography variant="h4" className={styles.headerTitle}>
            {selectedTab}
          </Typography>
        </header>
        <section className={styles["content-section"]}>
          {renderContent()}
        </section>
      </main>
    </div>
  );
};

export default BitlyLinks;
