import React, { useEffect, useState } from "react";
import { getAllQrCodes } from "../services";
import { Box, Button } from "@mui/material";
import QrCodeCard from "./QrCodeCard";
import GenerateQr from "./GenerateQr";
import styles from "./GeneratedQrList.module.css";

function GeneratedQrList() {
  const [qrCodes, setQrCodes] = useState([]);
  const [createNew, setCreateNew] = useState(false);
  const [qr, setQr] = useState(null);

  useEffect(() => {
    const fetchAllQrCodes = async () => {
      try {
        const response = await getAllQrCodes();
        setQrCodes(response);
      } catch (error) {
        console.error("Error fetching preview", error);
      }
    };

    fetchAllQrCodes();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
    <Button variant="contained" color="success" onClick={() => setCreateNew(true)}>Create New</Button>
    <Box
      className={createNew ? styles.generateContainer : styles.listContainer}
   sx={{paddingTop:"10px"}}
    >
      {createNew ? (
        <GenerateQr qr={qr} setQr={setQr} setCreateNew={setCreateNew} />
      ) : (
        <>
          <Box sx={{ width: "50%" }}>
            {qrCodes?.map((qrCode) => (
              <QrCodeCard
                key={qrCode._id}
                qrCode={qrCode}
                setQr={setQr}
                setCreateNew={setCreateNew}
              />
            ))}
          </Box>
          
        </>
      )}
    </Box>
    </Box>
  );
}

export default GeneratedQrList;
