import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your base URL
  timeout: 60000, // Timeout in milliseconds
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    
    if (error.response && error.response.status === 401) {
      console.log("yessssssssss")
      // Remove the token from localStorage
      localStorage.removeItem('authToken');
      
      // Optionally, you can also redirect the user to the login page
      window.location.href = '/login';
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
